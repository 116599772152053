<template>
  <v-card>
    <v-card-title>
      <v-icon color="primary" class="mr-4">mdi-file-account-outline</v-icon>
      <span class="subtitle-1">{{ $t('innerSideNav.billingInformation') }}</span>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pt-4">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field v-model="billingAddress.billingName"
                        :label="$t('billingName')"
                        disabled
                        filled>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" v-if="$store.state.authModule.user.financialData && $store.state.authModule.user.financialData.taxNumber">
          <v-text-field v-model="$store.state.authModule.user.financialData.taxNumber"
                        :label="$t('vatNumber')"
                        disabled
                        filled>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="billingAddress.countryLabel"
                        :label="$t('country')"
                        disabled
                        filled>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="billingAddress.zipCode"
                        :label="$t('postalCode')"
                        disabled
                        filled>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="billingAddress.city"
                        :label="$t('city')"
                        disabled
                        filled>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="billingAddress.publicPlaceName"
                        :label="$t('publicPlaceName')"
                        disabled
                        filled>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field v-model="billingAddress.publicPlaceType"
                        :label="$t('publicPlaceType')"
                        disabled
                        filled>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field v-model="billingAddress.houseNumber"
                        :label="$t('streetNumber')"
                        disabled
                        filled>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea v-model="billingAddress.otherAddressInfo"
                      :label="$t('otherDescription')"
                      disabled
                      filled>
          </v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="px-6 pb-4">
      <v-spacer></v-spacer>
      <v-btn class="primary">
        {{ $t('button.edit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'InvoiceInformation',
  components: {
    //
  },
  props: {
    //
  },
  beforeMount() {
    this.setUserBillingAddress();
  },
  data() {
    return {
      billingAddress: {},
      inputRules: {
        acceptInformation: [v => !!v || this.$t('inputMessages.acceptInformation')],
        required: [v => !!v || this.$t('inputMessages.requiredField')],
        maxFileSize: [
          value => !value.length || value.reduce((size, file) => size + file.size, 0) < 5000000 || this.$t('inputMessages.maxFileSizeRule'),
        ],
        emailRules: [
          v => /.+@.+\..+/.test(v) || this.$t('inputMessages.emailMatch'),
          v => !(/\s/.test(v)) || this.$t('inputMessages.emailNoSpace'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        phoneRules: [
          v => /^[0-9\-]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        noSpecialChar: [
          (v) => {
            return /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.noSpecialChar')
          },
        ],
      },
    };
  },
  methods: {
    setUserBillingAddress() {
      this.billingAddress = this.$store.state.authModule.user.addresses.find(item => item.addressType.id === 2);
      if (!this.billingAddress) {
        this.billingAddress = this.$store.state.authModule.user.addresses.find(item => item.addressType.id === 1);
        if (this.billingAddress.billingName === "" ) {
          this.billingAddress.billingName = `${this.$store.state.authModule.user.profile.lastName} ${this.$store.state.authModule.user.profile.firstName}`;
        }
      }
    }
  },
};
</script>

<style scoped>

</style>
