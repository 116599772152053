<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <invoice-information></invoice-information>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InvoiceInformation from "../../components/profile/InvoiceInformation.vue";

export default {
  name: 'InnerInvoiceInformation',
  components: {
    InvoiceInformation,
  },
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
